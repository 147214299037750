.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 100%;
  padding: 0 12px;
}

.bg-1 {
  background-color: #fddde4 !important;
}
.bg-2 {
  background-color: #cdebbc !important;
}
.bg-3 {
  background-color: #d1e8f2 !important;
}
.bg-4 {
  background-color: #cdd4f8 !important;
}
.bg-5 {
  background-color: #f6dbf6 !important;
}
.bg-6 {
  background-color: #fff2e5 !important;
}
.bg-7 {
  background-color: #d77f7a !important;
}
.bg-8 {
  background-color: #63a2c1 !important;
}
.bg-9 {
  background-color: #f2fce4 !important;
}
.bg-10 {
  background-color: #fffceb !important;
}
.bg-11 {
  background-color: #ecffec !important;
}
.bg-12 {
  background-color: #feefea !important;
}
.bg-13 {
  background-color: #fff3eb !important;
}
.bg-14 {
  background-color: #fff3ff !important;
}
.bg-15 {
  background-color: #f2fce4 !important;
}

.modal {
  --bs-modal-padding: 1rem;
  --bs-modal-header-padding: 1rem;
}

.btn {
  &.btn-sm:not(.btn-icon) {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px) !important;
    height: 40px !important;
  }
}

.border-dashed {
  border: 1px dashed #d9d9d9;
}

.Toastify__toast--success {
  background-color: #dcf1e4 !important;
  color: #0c622e !important;
}

.Toastify__toast--warning {
  background-color: #fbf1dd !important;
  color: #896110 !important;
}

.Toastify__toast--error {
  background-color: #fae4e8 !important;
  color: #b03d50 !important;
}

.ant-upload-select {
  width: 100%;
  height: 66px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}

.ant-upload-list.ant-upload-list-picture {
  .ant-upload-list-item {
    margin-top: 0px !important;
  }
}

.ant-table {
  border-radius: 0.475rem !important;

  .ant-table-thead {
    .ant-table-cell {
      text-align: center !important;
      // text-transform: uppercase !important;
      // background: var(--bs-success) !important;
      // color: white !important;
    }
  }

  .ant-table-expanded-row {
    .ant-table-cell {
      text-align: left !important;
    }
  }
}

.ant-select-dropdown {
  z-index: 1060 !important;
}

.avatar {
  .ant-upload-wrapper {
    display: flex !important;
    justify-content: center !important;
  }
  .ant-upload-list-picture-card,
  .ant-upload-list-item,
  .ant-upload {
    width: 150px !important;
    height: 150px !important;
    box-shadow: var(--bs-box-shadow) !important;
    border-radius: 4px !important;
  }
}

.card.card-flush {
  .card-header {
    border-bottom: 1px solid var(--bs-card-border-color) !important;
    min-height: 40px !important;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1050;
}

.scrollable-area {
  max-height: 450px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;

  .card {
    scroll-snap-align: start;
  }
}

.auth-bg-9 {
  background-image: url('../../../public/media/auth/bg9.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
